import React from 'react'
import App from 'App'
import { COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'

const GDPR = () => {
  return (
    <App>
      <SEO
        title="Vista Social and GDPR"
        description="Learn about Vista Social and General Data Protection Regulation (GDPR)."
        path="/gdpr/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
          Vista Social & GDPR
        </H1>
        <Box mx="auto" maxWidth="780px">
          <Flex flexDirection="column" mt="m" mb="xxl">
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              The GDPR (General Data Protection Regulation) is an important piece of legislation that is designed to
              strengthen and unify data protection and privacy laws for all individuals within the European Union (EU).
              The regulation was enacted on May 25, 2018.
            </Text>

            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              Who is impacted by the GDPR?
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              The GDPR enhances EU individuals’ privacy rights and places significantly enhanced obligations on
              organizations handling data. The provisions of the GDPR apply to any organization that processes personal
              data of individuals in the European Union, including tracking their online activities, regardless of
              whether the organization has a physical presence in the EU.
            </Text>
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              How does Vista Social comply with the GDPR?
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              Prior to the enactment of the GDPR, we implemented all necessary changes to our internal processes and
              procedures required to achieve and maintain compliance with the regulation. In addition, we updated our
              Data Privacy Agreement for maximum compliance.
              <br />
              <br />
              Since the enactment, as we add features and functionality to Vista Social, we thoroughly test all changes
              to verify and validate compliance with the GDPR.
            </Text>
            <Text textAlign="left" color="primaryText" fontWeight="bold" fontSize="m" mt="l">
              Does Vista Social offer a Data Processing Agreement for its users?
            </Text>
            <Text color="secondaryText" fontSize="m" textAlign="left" mt="m">
              Yes. We’ve updated our Data Processing Agreement (DPA) to be in compliance with the GDPR and other
              applicable privacy laws.
              <br />
              <br />
              You can review and digitally sign a copy of the DPA by emailing{' '}
              <a href="mailto:legal@vistasocial.com">legal@vistasocial.com</a>. We will countersign it and provide you
              with a fully executed downloadable copy via email within 10 business days.
              <br />
              <br />
              If you have any questions about Vista Social and the GDPR, contact us at{' '}
              <a href="mailto:legal@vistasocial.com">legal@vistasocial.com</a>.
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Footer />
    </App>
  )
}

export default GDPR
